
  import Vue from 'vue';
  import * as api from '@/api';

  export default Vue.extend({
    name: 'MemberEdit',
    data() {
      const validatePass = (rule, value, callback) => {
        const self = (this as any);
        if (self.form.newPassword === '') {
          callback(new Error('请输入密码'));
        } else {
          if (self.form.newPassword !== '') {
            (self.$refs.form2 as any).validateField('checkPassword');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        const self = (this as any);
        if (self.confirmed === '') {
          callback(new Error('请再次输入密码'));
        } else if (self.confirmed !== self.form.newPassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        confirmed: '',
        code: '',
        createdAt: '',
        lastLoginedAt: '',
        form: {
          id: '',
          userName: '',
          nickName: '',
          levelId: 0,
          country: '',
          province: '',
          city: '',
          isEnabled: true,
          newPassword: '',
        },
        countryList: [] as api.Regions[],
        provinceList: [] as api.Regions[],
        cityList: [] as api.Regions[],
        statistics: {
          collectedProductCount: 0,
          concernedBrandCount: 0,
          downloadedCount: 0,
          growthValue: 0,
          loginedNumberOfDays: 0,
        },
        rules: {
          userName: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          nickName: [
            { required: true, message: '请输入会员名', trigger: 'blur' },
          ],
          password: [
            { validator: validatePass, trigger: 'blur' },
          ],
          checkPassword: [
            { validator: validatePass2, trigger: 'blur' },
          ],
        },
        level: [] as api.MemberLevel[],
      };
    },
    computed: {
      memberInfo(): api.Member {
        return this.$store.state.memberInfo;
      },
    },
    watch: {
      memberInfo(data) {
        console.log(data);
      },
    },
    created() {
      this.$store.commit('breadcrumbList', [
        {
          name: '会员列表',
          path: 'memberList',
        },
        {
          name: '编辑会员',
          path: 'memberEdit',
        },
      ]);
      this.form = {
        id: this.memberInfo.id,
        userName: this.memberInfo.userName,
        nickName: this.memberInfo.nickName,
        levelId: this.memberInfo.levelId,
        country: this.memberInfo.country,
        province: this.memberInfo.province,
        city: this.memberInfo.city,
        isEnabled: this.memberInfo.isEnabled,
        newPassword: '',
      };
      this.code = this.memberInfo.code;
      this.createdAt = this.memberInfo.createdAt;
      this.lastLoginedAt = this.memberInfo.lastLoginedAt;
      this.setRegions();
      this.getLevel();
    },
    methods: {
      async getLevel() {
        const res = await api.getMemberLevel();
        if (!api.error(res)) {
          this.level = res.data;
        }
        const statistics = await api.getMemberStatistics({ id: this.form.id });
        if (!api.error(statistics)) {
          this.statistics = statistics.data;
        }
      },
      async setRegions() {
        await this.getRegions(null, 'countryList');
        let province = this.countryList[0].code;
        if (this.form.country) {
          const item = this.countryList.find((i) => i.name === this.form.country);
          if (item) province = item.code;
        }
        await this.getRegions(province, 'provinceList');
        let city = this.provinceList[0].code;
        if (this.form.province) {
          const item = this.provinceList.find((i) => i.name === this.form.province);
          if (item) city = item.code;
        }
        this.getRegions(city, 'cityList');
      },
      async changeCountry(val) {
        await this.getRegions(val.code, 'provinceList');
        this.getRegions(this.provinceList[0].code, 'cityList');
      },
      province(name, list) {
        return list.find((i) => i.name === name);
      },
      async changeProvince(val) {
        await this.getRegions(val.code, 'cityList');
        this.form.city = this.cityList[0].name;
      },
      async getRegions(parentCode, name: string) {
        const res = await api.getRegions({ parentCode });
        if (!api.error(res)) {
          this[name] = res.data;
        }
      },
      save(formName) {
        (this.$refs[formName] as any).validate(async (valid) => {
          if (valid) {
            if (formName === 'form' && this.form.newPassword) {
              this.save('form2'); // 密码验证
            } else {
              if (this.form.country && typeof (this.form.country) === 'object') this.form.country = (this.form.country as api.Regions).name;
              if (this.form.province && typeof (this.form.province) === 'object') this.form.province = (this.form.province as api.Regions).name;
              const res = await api.putMember(this.form);
              if (!api.error(res)) {
                this.$message.success('编辑成功');
                this.$router.push({
                  path: '/user/memberList',
                });
              } else {
                this.$message.error(res.error.message);
              }
            }
          } else {
            console.log('error submit!!');
            return false;
          }
          return true;
        });
      },
      cancel() {
        this.$router.push({
          path: '/user/memberList',
        });
      },
    },
  });

